import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

// Pages
import Homepage from './pages/Homepage';

// Contexts
import { AuthProvider } from './contexts/auth';

// Styles
import { Theme } from './theme/Theme';
import { GlobalStyle } from './theme/GlobalStyle';


const App = () => {
  return <>
    <ThemeProvider theme={Theme}>
      <AuthProvider>
        <GlobalStyle />

        <Router>

          <Routes>
            <Route path="/" element={<Homepage />} />
          </Routes>

        </Router>
      </AuthProvider>
    </ThemeProvider>
  </>;
}

export default App;
