import React, { useState, useEffect, useContext } from 'react';

interface AuthContext {
  getUser: () => void
  isLoggedIn: boolean
  login: (password: string) => Promise<boolean>
  logout: () => void
  getMolecule: (smiles: string, route: string, testType: string, dose: number) => Promise<false|MoleculeData>
}

const AuthContext = React.createContext<AuthContext>({} as AuthContext);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC = ({ children }) => {

  const [token, setToken] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  const getUser = () => {
    const localToken = localStorage.getItem('userToken') || '';

    if (localToken !== '') {
      // Check if token has not expired
      let base64Data = localToken.split('.')[1];
      let userData = JSON.parse(Buffer.from(base64Data, 'base64').toString());
      
      const now = new Date();
      const expires = new Date(userData.exp*1000);
      if (now > expires) {
        // Token expired
        localStorage.removeItem('userToken');
        setToken('');
        setIsLoggedIn(false);
      }

      setToken(localToken);
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
      setToken('');
    }
    
  }

  const storeUser = () => {
    if (token !== '') {
      localStorage.setItem("userToken", token);
    }
  }

  const login = async (password: string) => {
    // Returns 401 if not authorized
    const response = await fetch(`${process.env.REACT_APP_API_URL}/auth`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ password })
    });

    if (response.status === 200) {
      const data = await response.json();
      setToken(data.token);
      setIsLoggedIn(true);
      return true;
    } else {
      setIsLoggedIn(false);
      return false;
    }
  }

  const logout = () => {
    setToken('');
    setIsLoggedIn(false);
    localStorage.removeItem('userToken');
  }

  const getMolecule = async (smiles: string, route: string, testType: string, dose: number) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/molecule`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ 
        smiles: smiles,
        test_type: testType,
        route: route,
        dose: dose
       })
    });

    if (response.status === 200) {
      const data: MoleculeData = await response.json();
      return data;
    } else {
      return false;
    }
  }

  useEffect(() => {
    storeUser();
  }, [token]);

  useEffect(() => {
    getUser();
  }, []);

  return(
    <AuthContext.Provider
      value={{
        getUser: getUser,
        isLoggedIn: isLoggedIn,
        login: login,
        getMolecule: getMolecule,
        logout: logout
      }}
    >
      {children} 
    </AuthContext.Provider>
  )
}

export default AuthContext;