import React from 'react';

import * as styles from './NavbarStyles';

import { useAuth } from './../contexts/auth';

const Navbar = () => {
  const { logout } = useAuth();

  return <>
  <styles.Navbar>
    <styles.InformationContainer>
    <styles.Title>Consone AI Preview Site</styles.Title>
    <styles.Info>Preview site for initial toxicology modelling by Consone AI.</styles.Info>
    </styles.InformationContainer>
    <styles.SignOutButton onClick={() => logout()}>Sign Out</styles.SignOutButton>
  </styles.Navbar>
  </>;
}

export default Navbar;