import styled from 'styled-components';

export const ContentWrapper = styled.div`
  width: 90%;
  max-width: 80rem;
  margin: 0 auto;
`;

export const TwoColumn = styled.div`
  width: 100%;
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;

  .form-wrap-height {
    height: 100%;
    display: flex;
    flex-direction: column;
    
    textarea {
      flex-grow: 1;
      margin: 0;  
    }
  }


  .molecule {
    padding: 20px;
    
    border-radius: 4px;
    border: 1px solid hsl(0,0%,80%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .input-summary {
    padding: 20px;
    
    border-radius: 4px;
    border: 1px solid hsl(0,0%,80%);
    
    p {
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export const SimpleTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid hsl(0,0%,80%);
  margin-bottom: 1rem;
  border-radius: 4px;

  th, td {
    border: 1px solid hsl(0,0%,80%);
    padding: 0.5rem;
  }

  td > span {
    font-weight: bold;
  }

  td:first-letter {
    text-transform:capitalize;
  }
`;

export const Content = styled.div`
  margin-top: 2rem;
  min-height:l cac(100vh - 2rem);

  h1 {
    color: ${props => props.theme.colors.secondary};
  }

  h3 {
    margin: 1rem 0;
  }

  p.header-info {

  }

  .error {
    span {
      color: ${props => props.theme.colors.primary};
    }
    font-family: ${props => props.theme.fonts.heading};
    font-weight: bold;
    border-left: 5px solid ${props => props.theme.colors.primary};
    padding-left: 0.5rem;
  }

  label {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 700;
  }

  input, textarea {
    margin: 0.4rem 0;
    font-size: 1rem;
    font-family: ${props => props.theme.fonts.heading};
    font-weight: 700;
    padding: 0.5rem 0.8rem;
    border-radius: 4px;
    border: 1px solid ${props => props.theme.colors.secondary};
    color: #333;
    max-width: 100%;
  }

  textarea {
    resize: none;
    width: 100%;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.colors.secondary};
  margin: 1rem 0;
`;

export const ButtonGroup = styled.div`
  button {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }       
  }
`;

interface SubmitButtonProps {
  disabled?: boolean;
  color?: string;
}

export const SubmitButton = styled.button<SubmitButtonProps>`
  color: ${props => props.color ? props.theme.colors[props.color] : props.theme.colors.secondary};
  background-color: white;
  border: 1px solid ${props => props.color ? props.theme.colors[props.color] : props.theme.colors.secondary};
  border-radius: 4px;
  font-size: 1rem;
  font-family: ${props => props.theme.fonts.heading};
  font-weight: 700;
  padding: 5px;
  cursor: pointer;

  ${props => props.disabled && `
    opacity: 0.7;
    cursor: not-allowed;
    &:hover {
      
    }
  `}

  &:hover {
    ${props => !props.disabled && `
      color: white;
      background-color: ${props.color ? props.theme.colors[props.color] : props.theme.colors.secondary};
    `}
  }
`;

export const SimpleForm = styled.div`
  & > * {
    display: block;
  }

  & button {
    margin-top: 1rem;
  }

  input, textarea {
    margin: 0.4rem 0;
    font-size: 1rem;
    font-family: ${props => props.theme.fonts.body};
    font-weight: 400;
    padding: 0.5rem 0.8rem;
    border-radius: 4px;
    border: 1px solid hsl(0, 0%, 80%);
    color: #333;
    max-width: 100%;


    &:focus {
      outline: none !important;
      border: 2px solid ${props => props.theme.colors.primary};
    }
  }

`;