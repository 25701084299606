import styled from 'styled-components';

export const Navbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InformationContainer = styled.div``;

export const Title = styled.h1``;

export const Info = styled.p``;

export const SignOutButton = styled.a`
  color: ${props => props.theme.colors.secondary};
  background-color: white;
  border: 1px solid ${props => props.theme.colors.secondary};
  border-radius: 4px;
  font-family: ${props => props.theme.fonts.heading};
  font-weight: 700;
  font-size: 1rem;
  padding: 5px;
  cursor: pointer;
  &:hover {
    color: white;
    background-color: ${props => props.theme.colors.secondary};
  }
`;