export const Theme = {
  fonts: {
    body: '"Lora", serif',
    heading: '"Poppins", sans-serif',
  },
  colors: {
    primary: 'rgb(16, 152, 173)',
    primaryFaded: 'rgba(16, 152, 173, 0.2)',
    secondary: 'rgb(12, 166, 120)',
    secondaryFaded: 'rgba(12, 166, 120, 0.2)',
    accents: {
      0: 'rgb(112, 72, 232)',
      1: 'rgb(28, 126, 214)',
      2: 'rgb(16, 152, 173)',
      3: 'rgb(12, 166, 120)',
    }
  },
  breakpoints: {
    mobile: '800px'
  }
}