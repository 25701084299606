import React, { useState, useEffect } from 'react';
import Select, { SingleValue, Theme as SelectTheme } from 'react-select';

import { useAuth } from './../contexts/auth';

import SignIn from './../components/SignIn';
import Navbar from './../components/Navbar';
import { Theme } from '../theme/Theme';

// Styles
import * as PageStyles from './PageStyles';
import DrawMolecule from '../components/DrawMolecule';

const selectTheme = (theme: SelectTheme) => ({
  ...theme,
  borderRadius: 4,
  colors: {
    ...theme.colors,
    primary25: Theme.colors.primaryFaded,
    primary: Theme.colors.primary,
  },
});

const Homepage = () => {
  const { getUser, isLoggedIn, getMolecule } = useAuth();
  
  const [moleculeData, setMoleculeData] = useState({} as MoleculeData);
  const [moleculeSet, setMoleculeSet] = useState(false);

  const [smilesInput, setSmilesInput] = useState('');
  const [routeInput, setRouteInput] = useState({} as SingleValue<{ value: string; label: string; }>);
  const [testTypeInput, setTestTypeInput] = useState({} as SingleValue<{ value: string; label: string; }>);
  const [doseInput, setDoseInput] = useState(0);
  
  const [error, setError] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const submitMolecule = async () => {
    // Check inputs not empty
    if (smilesInput === '') {
      setError('Please enter a SMILES string');
      return;
    }
    if (routeInput === null) {
      setError('Please select a route');
      return;
    }
    if (testTypeInput === null) {
      setError('Please select a test type');
      return;
    }
    if (doseInput === 0) {
      setError('Please enter a dose');
      return;
    }

    // Send molecule to backend and receieve molecule data
    setIsLoading(true);
    const response = await getMolecule(smilesInput.trim(), routeInput.value, testTypeInput.value, doseInput);
    setIsLoading(false);

    if (response) {
      setMoleculeData(response);
      setMoleculeSet(true);
    } else {
      setError('Error: Invalid SMILES');
    }
  }

  const resetMolecule = () => {
    setMoleculeSet(false);
    setError('');
    
    setSmilesInput('');
    setRouteInput({} as SingleValue<{ value: string; label: string; }>);
    setTestTypeInput({} as SingleValue<{ value: string; label: string; }>);
    setDoseInput(0);
  }

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      resetMolecule();
    }
  }, [isLoggedIn]);

  return <>
    {!isLoggedIn && <>
      <SignIn />
      {/* Modal to sign in */}
    </>}

    {isLoggedIn && <>
      <PageStyles.ContentWrapper>
        <PageStyles.Content>
          <Navbar />
          <PageStyles.Divider />

          {!moleculeSet && <>
            <h3>Enter the following data for the molecule.</h3>

            {error && <>
              <p className="error"><span>Error:</span>&nbsp;&nbsp;{error}</p>
            </>}

            <PageStyles.SimpleForm>
              <PageStyles.TwoColumn>
                <div className="form-wrap-height">
                  <label htmlFor={'smiles'}>SMILES:</label>
                  <textarea
                    name={'smiles'}
                    placeholder={'Input SMILES'}
                    value={smilesInput}
                    onChange={(e) => setSmilesInput(e.target.value)}
                  />
                </div>
                <div className="molecule">
                  <p>Molecule preview:</p>
                  <DrawMolecule smiles={smilesInput} />
                </div>
              </PageStyles.TwoColumn>

              <label htmlFor={'route'}>Route:</label>
              <Select
                defaultValue={routeInput}
                name={'route'}
                onChange={setRouteInput}
                theme={selectTheme}
                options={[
                  { value: 'oral', label: 'Oral' },
                  { value: 'intrarenal', label: 'Intrarenal' },
                  { value: 'intraduodenal', label: 'Intraduodenal' },
                  { value: 'In Vitro', label: 'In Vitro' },
                ]}
              />

              <label htmlFor={'test'}>Test type:</label>
              <Select
                defaultValue={testTypeInput}
                name={'test'}
                onChange={setTestTypeInput}
                theme={selectTheme}
                options={[
                  { value: 'tdlo', label: 'TDLo' },
                  { value: 'ld50', label: 'LD50' },
                  { value: 'iclo', label: 'ICLo' },
                  { value: 'lclo', label: 'LCLo' },
                ]}
              />

              <label htmlFor="dose">Dose in mg/kg:</label>
              <input 
                name="dose"
                type="number"
                placeholder={'Dose'}
                value={doseInput}
                onChange={(e) => setDoseInput(Number(e.target.value))}
              />
              
              <PageStyles.SubmitButton
                color={'primary'}
                onClick={() => submitMolecule()}
                disabled={isLoading}
              >
                Submit
              </PageStyles.SubmitButton>
            </PageStyles.SimpleForm>
          </>}

          {moleculeSet && <>
            <h3>Molecule Inputs</h3>
            <PageStyles.TwoColumn>
              <div className="input-summary">
                <p><b>SMILES:</b> {smilesInput}</p>
                <p><b>Route:</b> {routeInput?.label}</p>
                <p><b>Test type:</b> {testTypeInput?.label}</p>
                <p><b>Dose:</b> {doseInput} mg/kg</p>

              </div>
              <div className="molecule">
                <p>Molecule preview:</p>
                <DrawMolecule smiles={smilesInput} />
              </div>
            </PageStyles.TwoColumn>

            <h3>Model Outputs:</h3>
            <PageStyles.SimpleTable>
              <thead>
                <tr>
                  <th>Organism</th>
                  <th>Toxicology?</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(moleculeData.data).map((key) => {
                  return <tr key={key}>
                    <td>{key.replace(/_/g, " ")}</td>
                    <td>{moleculeData.data[key] ? <span>True</span> : "False"}</td>
                  </tr>
                })}
              </tbody>
            </PageStyles.SimpleTable>
            

            <PageStyles.ButtonGroup>
              
              <PageStyles.SubmitButton
                color={'secondary'}
                onClick={() => setMoleculeSet(false)}
              >
                Update values
              </PageStyles.SubmitButton>

              <PageStyles.SubmitButton
                color={'primary'}
                onClick={() => resetMolecule()}
              >
                Reset: Enter a new molecule
              </PageStyles.SubmitButton>

            </PageStyles.ButtonGroup>
          </>}

        </PageStyles.Content>
      </PageStyles.ContentWrapper>
    </>}
  </>;
}

export default Homepage;