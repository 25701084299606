import React, { useState } from 'react';

import * as styles from './ModalStyles';
import { useAuth } from './../contexts/auth';

const SignIn = () => {
  const { login } = useAuth();
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSubmitPassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendDetails();
  }

  const sendDetails = async () => {
    setLoading(true);
    let result = await login(password);
    if (!result) setError('Invalid password');
    setLoading(false);
  }

  return (
    <styles.ModalWrapper>
    <styles.ModalBox>
      <styles.ModalHeader>
        <h3>Access restricted</h3>
        <h2>You need to sign in to access the content of this site</h2>
      </styles.ModalHeader>
      <styles.Divider />
      <styles.ModalBody>
        <p>To access the site please provide the password. If you believe you need access, but cannot log in please contact <a href="mailto:mo@consoneai.com">mo@consoneai.com</a></p> 
        <styles.Divider />
        {error && <p className={'danger'}>{error}</p>}
        <form onSubmit={handleSubmitPassword}>
          <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
          <styles.SubmitButton disabled={loading} onClick={() => sendDetails()}>Sign in</styles.SubmitButton>
        </form>
      </styles.ModalBody>
    </styles.ModalBox>
  </styles.ModalWrapper>
  );
}

export default SignIn;