import React, { useState, useEffect } from 'react';
import SmilesDrawer from 'smiles-drawer';

interface DrawMoleculeProps {
  smiles: string;
}

const DrawMolecule: React.FC<DrawMoleculeProps> = ({smiles}) => {
  let smilesDrawer = new SmilesDrawer.Drawer({
    width: 300,
    height: 200
  });

  const updateSmiles = (smiles: string) => {
    SmilesDrawer.parse(smiles.trim(), (tree: any) => {
      // Draw to the canvas
      smilesDrawer.draw(tree, "smiles-output", "light", false);
      // Alternatively, draw to SVG:
      // svgDrawer.draw(tree, 'output-svg', 'dark', false);
    });
  };

  useEffect(() => {
    updateSmiles(smiles)
  }, [smiles]);

  return <>
    <canvas id="smiles-output">
      
    </canvas>
  </>;
}

export default DrawMolecule;